import { css } from "@emotion/react";
import { rem } from "app/styles/utils";

export { container, centerTxt, bannerTxt, bodyTxt } from "app/styles/shared";

export const heroBox = css`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(60)};
`;

export const heroContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const heroMargin = css`
  margin: 0 auto ${rem(30)};
`;
