import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

const GET_SDGS_QUERY = graphql`
  query {
    allSdgsJson(filter: { sdg_id: { ne: "0" } }) {
      nodes {
        ...SDG
      }
    }
  }
`;

export const useSDGsData = () => {
  const data = useStaticQuery(GET_SDGS_QUERY);
  return _get(data, "allSdgsJson.nodes") || [];
};

export default useSDGsData;
