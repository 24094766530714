import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import {
  cardFlipRoot,
  CardFace,
  CardSide,
  cardBack,
} from "../styles/card-flip";

import Image from "./image";

const { white } = colors;

const bgColor = ({ color }) => css`
  ${color && `background-color: ${color}`};
`;

// const debugCenter = css`
//   background: linear-gradient(90deg, #ffc0cb 50%, #00ffff 50%);
// `;

export const SDGTile = styled.figure`
  width: ${rem(200)};
  height: ${rem(200)};
  margin: 0 auto;
  ${cardFlipRoot};
  white-space: normal;
  cursor: pointer;
`;

export const SDGFace = styled(CardFace)``;

const sdgSide = css`
  display: flex;
  flex-direction: column;
  border-radius: ${rem(20)};
  margin: 0;
  color: ${white};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const SDGFront = styled(CardSide)`
  ${sdgSide};
  ${bgColor};
  position: relative;
  padding: ${rem(15)};
`;

export const SDGBack = styled(CardSide)`
  ${sdgSide};
  ${cardBack};
  ${bgColor};
  justify-content: center;
  padding: 0 ${rem(15)};
`;

export const SDGImage = styled(Image)`
  position: absolute !important; /* say no to Gatsby Image */
  top: 0;
  left: 0;
  width: 100%;
`;

// export const SDGNum = styled.small`
//   display: block;
//   ${fontSize(40, 49)};
//   font-weight: ${fontWeights.book};
//   margin-bottom: ${rem(5)};
// `;

// export const SDGName = styled.span`
//   display: block;
//   ${fontSize(18, 28)};
//   font-weight: ${fontWeights.black};
//   text-transform: uppercase;
//   margin: 0;
// `;

export const SDGText = styled.p`
  display: block;
  ${fontSize(13, 20)};
  font-weight: ${fontWeights.book};
  margin: 0;
`;
