import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import About from "app/templates/about";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "about" }) {
        ...AboutPage
      }
    }
  `);

  return <About {..._get(data, "contentJson")} />;
};

export default AboutPage;
