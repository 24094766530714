import React, { useState } from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/sdg-tile.styles";

const S = {
  ...stylesShared,
  ...styles,
};

const SDGTile = ({
  id,
  sdg_id,
  sdg_name,
  sdg_desc,
  sdg_image,
  color,
  flipped,
  setFlipped,
}) => (
  <S.SDGTile
    onClick={
      typeof setFlipped === "function"
        ? () => (flipped ? setFlipped(false) : setFlipped(id))
        : undefined
    }
  >
    <S.SDGFace flipped={flipped}>
      <S.SDGFront active={!flipped} color={color} as="h1">
        <S.SDGImage image={sdg_image} />
        <S.VisuallyHidden>
          <span>SDG {sdg_id}</span>
          <span>{sdg_name}</span>
        </S.VisuallyHidden>
      </S.SDGFront>
      <S.SDGBack active={flipped} color={color}>
        <S.SDGText>{sdg_desc}</S.SDGText>
      </S.SDGBack>
    </S.SDGFace>
  </S.SDGTile>
);

export default SDGTile;

export const SDGTilePreview = (props) => {
  const [flipped, setFlipped] = useState(false);
  return (
    <SDGTile
      flipped={flipped}
      setFlipped={setFlipped}
      id={_get(props, "sdg_id")}
      {...props}
    />
  );
};

export const sdgFragment = graphql`
  fragment SDG on SdgsJson {
    color
    id
    sdg_image {
      childImageSharp {
        gatsbyImageData(width: 200, height: 200)
      }
    }
    cardImage {
      childImageSharp {
        gatsbyImageData(width: 300, height: 360)
      }
    }
    sdg_desc
    sdg_id
    sdg_name
  }
`;

export const sdgFields = [
  {
    label: "Id",
    name: "sdg_id",
    widget: "hidden",
  },
  {
    label: "Name",
    name: "sdg_name",
    widget: "string",
  },
  {
    label: "Description",
    name: "sdg_desc",
    widget: "text",
  },
  {
    label: "Color",
    name: "color",
    widget: "hidden",
  },
  {
    label: "Image",
    name: "cardImage",
    widget: "image",
  },
  {
    label: "Challege Page Image",
    name: "coverImage",
    widget: "image",
  },
  {
    label: "SDG Image",
    name: "sdg_image",
    widget: "hidden",
  },
];
