import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem } from "../styles/utils";

const flipped = ({ flipped }) => css`
  transform: ${(flipped && "translateX(-100%) rotateY(-180deg)") ||
    "translateX(0) rotateY(0deg)"}
    translateZ(0);
`;

// const hideInactive = ({ active }) => css`
//   opacity: ${active ? "1" : "0"};
// `;

export const cardFlipRoot = css`
  perspective: ${rem(600)};
`;

export const CardFace = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform-origin: center right;
  ${flipped};
`;

export const CardSide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: translateZ(0);
`;

export const cardBack = css`
  transform: rotateY(180deg) translateZ(0);
`;
