import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import { btnField } from "app/components/btn";
import HeroText, { heroTextField } from "app/components/hero-text";
import HeroBar, { heroBarField } from "app/components/hero-bar";
import IntroBlock from "app/components/intro-block";
import Layout from "../components/layout";
import SDGTile from "app/components/sdg-tile";
import SplitBox from "app/components/split-box";
import SEOMeta, { seoField } from "app/components/seo";

import genLogo from "app/images/gen-logo.svg";

import mindset from "../../static/videos/about_mindset.mp4";
import digDeeper from "../../static/videos/about_dig_deeper.mp4";
import aboutNFTEBG from "../../static/videos/about_nfte.mp4";

import { useSDGsData } from "app/hooks/use-sdgs-data";

import Carousel from "app/components/carousel";

const videos = {
  mindset: mindset,
  dig_deeper: digDeeper,
  about: aboutNFTEBG,
};

const About = ({ aboutNFTE, intro, sdgList, title, ...props }) => {
  const sdgs = useSDGsData();

  return (
    <Layout>
      <SEOMeta title={title} {..._get(props, "seo")} />
      <article>
        <IntroBlock title={title} intro={intro} />
        <SplitBox
          items={_get(props, "whyWSI", []).map(({ video: v, ...row }) => ({
            ...row,
            video: (v && videos[v]) || undefined,
            img: (v === "genglobal" && genLogo) || undefined,
          }))}
        />
        <section>
          <HeroText {...sdgList} />
          <Carousel
            items={sdgs
              .map((sdg) => ({ ...sdg, id: sdg.sdg_id }))
              .sort((a, b) => parseInt(a.sdg_id, 10) - parseInt(b.sdg_id, 10))}
            width={260}
            Tile={<SDGTile />}
          />
        </section>
        <HeroBar {...aboutNFTE} videoBg={aboutNFTEBG} />
      </article>
    </Layout>
  );
};

export default About;

export const aboutFragment = graphql`
  fragment AboutPage on ContentJson {
    ...SEO
    title
    intro
    whyWSI {
      ...SplitBox
    }
    sdgList {
      ...HeroCTA
    }
    aboutNFTE {
      ...HeroCTA
    }
  }
`;

export const aboutFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "markdown",
  },
  {
    label: "Why WSI",
    name: "whyWSI",
    widget: "list",
    fields: [
      {
        label: "Title",
        name: "title",
        widget: "string",
      },
      {
        label: "Headline",
        name: "headline",
        widget: "string",
      },
      {
        label: "Text",
        name: "text",
        widget: "markdown",
      },
      btnField,
      {
        label: "Image or video",
        name: "video",
        widget: "select",
        options: ["genglobal", "mindset", "dig_deeper", "about"],
        default: "none",
      },
    ],
  },
  {
    ...heroTextField,
    label: "SDG List",
    name: "sdgList",
  },
  {
    ...heroBarField,
    label: "About NFTE",
    name: "aboutNFTE",
  },
];
