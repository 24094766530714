import React from "react";

import Btn, { btnField } from "app/components/btn";

import * as S from "app/components/hero-text.styles";

export const HeroText = ({ title, text, btn }) => (
  <section css={S.heroBox}>
    <div css={[S.container, S.heroContent]}>
      {title && <h2 css={[S.centerTxt, S.bannerTxt, S.heroMargin]}>{title}</h2>}
      {text && <p css={[S.centerTxt, S.bodyTxt, S.heroMargin]}>{text}</p>}
      {btn && <Btn {...btn} />}
    </div>
  </section>
);
export default HeroText;

export const heroTextField = {
  label: "CTA Block",
  name: "ctaBlock",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    {
      label: "Text",
      name: "text",
      widget: "string",
      required: false,
    },
    btnField,
  ],
};
